
import { Component, Provide, Vue, Watch } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import EventBusMixin from '~/mixins/EventBusMixin.vue'
import Account from '~/components/account/Account.vue'

@Component({
  components: {
    Account,
    LazyHydrate,
    Notify: () => import('@pp-frontend/authorization/component/Notify.vue')
  },
  mixins: [EventBusMixin]
})
export default class extends Vue {
  @Provide('excludeCacheFunction') clearPageCache: (name: string) => void = (
    name: string
  ) => {
    this.keepAliveExclude.push(name)

    this.$nextTick(() => {
      this.keepAliveExclude.pop()
    })
  };

  notifyIsOpen: boolean = false;
  isOpened = false;
  showNotify: boolean = false;

  notificationsReceived = false;
  notificationsCount: number | null = null;
  hideFooterNameRoutes: string[] = [
    'payment',
    'payment-fail',
    'search-fssp',
    'search-fns',
    'email-land'
  ];

  keepAliveExclude: string[] = ['payment', 'payment-fail'];

  get childKey () {
    return (this.$route.name || '').startsWith('contestation-search')
      ? 'contestation-search?' + require('qs').stringify(this.$route.query)
      : this.$route.fullPath
  }

  get hideFooter () {
    return !this.hideFooterNameRoutes.includes(this.$route.name || '')
  }

  beforeMount () {
    this.$ga.updateStorage()
  }

  mounted () {
    this.onRouteChange()
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }

    this.$eventBus.$on('showPopup', (val) => {
      this.isOpened = val.isShow
    })
  }

  @Watch('$route', { deep: true })
  onRouteChange () {
    this.showNotify = !(
      this.$route.path.startsWith('/search/') ||
      this.$route.name === 'payment' ||
      this.$route.name === 'payment-fail'
    )
    this.switchFavicon()
  }

  switchFavicon () {
    let n = 0
    const favicon: HTMLLinkElement = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement

    const interval = setInterval(() => {
      n = (n + 1) % 2

      const count = this.$store.getters['requisites/getCount']

      if (!this.showNotify) {
        return
      }

      if (count === 0) {
        clearInterval(interval)
        return
      }

      if (n === 0) {
        favicon.href = '/favicon/favicon.ico'
      } else if (count < 10) {
        favicon.href = '/favicon/favicon' + count + '.svg'
      } else {
        favicon.href = '/favicon/faviconN.svg'
      }
    }, 2000)
  }

  setNotifications (count: number) {
    this.notificationsReceived = true
    this.notificationsCount = count
  }
}
